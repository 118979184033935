import { IonContent, IonIcon, IonModal } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import { useTranslation } from 'react-i18next';
import PhoneBlack  from '../../assets/images/phone-black.svg';
import { useQuery } from '@tanstack/react-query';
import './ClinicalCaseRetargetingComponent.scss';
import { GetCompanyBenefitsInfoResponse } from '../../utils/ApiTypes';
import CallSupportButton from '../CallSupport/CallSupportButton';
import { ExitButton } from '../ExitButton/ExitButton';
import { JourneyApiClient } from '../../utils/JourneyApiClient';

interface Props {
    benefitsInfo: GetCompanyBenefitsInfoResponse;
}

export const ClinicalCaseRetargetingComponent: React.FC<Props> = (props) => {

    const { currentUser, handleGeneralError, language } = useContext(ApplicationContext);
    const [isClinicalCaseRetargetingModalOpen, setIsClinicalCaseRetargetingModalOpen] = useState<boolean>(currentUser?.hasClinicalCaseRetargetingModal ?? false);
    const { t } = useTranslation();

    async function handleClose() {
        await JourneyApiClient.setClinicalCareRetargetingModalSeen();
        setIsClinicalCaseRetargetingModalOpen(false);
    }

    return (
        <IonModal 
            onWillDismiss={handleClose}
            isOpen={isClinicalCaseRetargetingModalOpen}
            className="clinical-case-retargeting-modal">
            <IonContent className="clinical-case-retargeting-content">
                <div className="exit-button-container">
                    <ExitButton onClick={handleClose}/>
                </div>
                <div className="clinical-case-retargeting-container">
                    <div className="icon-container"><IonIcon src={PhoneBlack} className={"clinical-case-retargeting-icon"}/></div>
                    <p className="clinical-case-retargeting-title header-4">{t("Thank you for choosing Journey Proactive EAP")}</p>
                    <p className="clinical-case-retargeting-text body-medium">
                        {t("We hope you found the experience valuable. Remember, you can always call us again to connect with a master’s level clinician about any topic, free of charge and confidentially.")}
                    </p>
                    <div className="proactive-care-buttons-wrapper">
                        <div className="proactive-care-buttons-container">
                             {props.benefitsInfo && props.benefitsInfo.supportPhones.length > 0 &&
                                <CallSupportButton 
                                    className='proactive-care-button header-6-variant'
                                    benefitsInfo={props.benefitsInfo}
                                    onClick={handleClose}
                                    hideList={true}
                                />
                            }
                        </div>
                    </div>
                    <p className="clinical-case-retargeting-text body-medium">
                        {t("Your family and friends can take advantage of these services too—simply share the phone number for their own free and confidential support!")}
                    </p>
                </div>
            </IonContent>
        </IonModal>
    );
};

